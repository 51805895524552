import React, { Component } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Row } from "react-bootstrap";
import BlogCard from "./BlogCard";
import BlogList from "../models/BlogList";

class FilteredBlogPosts extends Component {
  constructor(props) {
    super();
    this.state = {
      uniqueYears: [],
      blogList: new BlogList(props.articles),      
      minsToRead: {},
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {

    // Select the latest year to start with
    /* var years = this.state.blogList.getUniqueYears();
    if (years && years.length > 0)
    {
      this.setState({yearSelected:years[0]});
      this.filterArticles(years[0]);
    }
    else {
      this.setState({yearSelected:"All"});
    } */

    this.setState({yearSelected:"All"});
    
  }

  handleClick(evt) {
    this.filterArticles(evt.toString());
  }

  filterArticles(year) {
    this.setState((state) => ({
      yearSelected: year,
    }));
    this.state.blogList.setFilteredArticles(year);
  }
  render() {
    if (!this.state.yearSelected)
      return (<></>);
    return (
      <>
        <div>
          <label>Filter posts by year</label>
          <DropdownButton
            id="yeardropdown"
            title={this.state.yearSelected}
            onSelect={this.handleClick}
          >
            <Dropdown.Header>
              <b>Filter by year</b>
            </Dropdown.Header>

            {this.state.blogList.getUniqueYears().map((year) => (
              <Dropdown.Item
                id={`yeardropdown${year}`}
                key={`yeardropdown${year}`}
                eventKey={year}
              >
                {year}
              </Dropdown.Item>
            ))}

            <Dropdown.Item
              key={`yeardropdownAll`}
              id={`yeardropdownAll`}
              eventKey="All"
            >
              All
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <br />
        <Row>
          {this.state.blogList.getFilteredArticles().map((item) => (
            <BlogCard
              key={item.name}
              item={item}
              minsToRead={this.state.minsToRead[item.name]}
            />
          ))}
        </Row>
      </>
    );
  }
  
    // This way of fetching seems much slower
    // than invoking this in BlogCard for
    // one article at at time
    /* var list = []
    this.props.articles.forEach((element) => {
      var body = {
        name: element.name,
        fileurl: `${process.env.REACT_APP_CONTENT_ROOT_URL}/${element.name}.html`,
      };
      list.push(body);
    });
    fetch(process.env.REACT_APP_MINS_TO_READ_URL, {
      method: "POST",
      body: JSON.stringify(list),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        var respList = JSON.parse(data);      
        var currMinsToReadArray = {}
        console.log(currMinsToReadArray)
        respList.forEach((item) => {
          var text = item.minstoread + " minutes to read";            
          currMinsToReadArray[item.name] = text;
        });
        console.log(currMinsToReadArray)
        this.setState({ minsToRead: currMinsToReadArray });
      })
      .catch((error) => {
        console.error(error);
      });  */
}

export default FilteredBlogPosts;
