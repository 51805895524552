import React from "react";
import { Layout } from "../components/Layouts";
import meImage from "../assets/unprofile.jpg";
export const About = () => (
  <>
    <Layout>
      <div>
        <br />
        <br />
      </div>
      <div>
        <figure>
          <img src={meImage} style={{ maxWidth: '45%', maxHeight: '45%' }} alt="examples" className="meImage"></img>
          <figcaption><i>Raji Rajagopalan. Partner Director of Engineering at Microsoft.
            Author of the book 'Daring to be Different'. Global Speaker. </i></figcaption>
        </figure>

      </div>
      <div>
        <br />
        <br />
      </div>
      <div className="about">
        <p>
          When I was a little girl, I rarely played with dolls. My go-to
          pastimes were to play teacher or to play doctor. I didn't become a
          teacher or a doctor in real life. Not yet, at least. I became a tech
          leader, a writer, a coach/mentor, and a keynote speaker.
        </p>
        <p>
          In my day job, I am an engineering leader at Microsoft. I believe
          that Cloud and data are two superpowers every company can use to
          digitally transform. I believe there is a lot of power in data, but
          there are many, many gotchas as well. For one, data can be easily
          misunderstood and misinterpreted. Using data in a trusted and ethical
          manner is important, but often done badly or ignored by organizations.
        </p>
        <p>
          I spent the last decade+ using data and the Cloud to transform the
          work we do at Microsoft, as well as the work our customers do. Along
          with my AMAZING team, I shipped products like Windows, Compatibility Online Service,
          Windows Analytics, Ready For Windows , and Desktop Analytics. I currently run a global
          team of devs across USA, Ireland, China, Kenya, and Nigeria building a Cloud service for
          remote intelligent testing called <a href="http://aka.ms/testbase">Test Base for M365</a> and ensuring flawless  
          application experience on Windows. 
        </p>
        <p>
        I seek to model quiet power and lead my teams mindfully. While I find great satisfaction in being a plumber – building
          fundamental software systems that are performant, scalable, and
          reliable – I also love being an artist for my customers: storytelling
          with data, connecting with all my humans (my partners, customers, and
          developers), and building tech that touches and delights people. As a leader, my mission is to 
          build <b>diverse, innovative teams that do outstanding work</b>, 
            where every employee feels like <b>they belong and can make a difference in their own lives and in the world</b>. 
        </p>
        <p>
          When I am not building tech, I am speaking, or writing words that I
          hope can further people’s potential – just the way tech does. My
          current topics of obsession include Behavioural Science, Upskilling, 
          Ethics and the Art of Testing code. My perennial interests include 
          leadership, meditation, history, and the mountains.
        </p>
      </div>
    </Layout>
  </>
);
export default About;
