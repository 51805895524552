import React from "react"
import { Container } from "react-bootstrap";
import Mailchimp from 'react-mailchimp-form'

const MailchimpURL = 'https://rajiraj.us4.list-manage.com/subscribe/post?u=adbdeafadb4392995a52b89fd&amp;id=9171b41c5b';

const SubscribeBox = () => {
    return (
      
        <div className="subscribeBox">
          <Container>
          <h3>Notifications on my upcoming events, professional advice for technology professionals that is proven to work, reflections on 
            my personal experience, book recommendations, and much more...
          </h3>
          <br/>
          <h4>
            If you liked what you read in my <a href="/blog">blog</a>, and you do not want to miss
            my next articles, subscribe using your email address.
          </h4>
          <br/>
          <p>
            Subscribing is risk-free and doesn't cost anything. I do not 
            share your email address with any third parties or use it for any other 
            purpose than sending installments of my blog posts. You can cancel your subscription at 
            any time using the link that will be sent in every one of the emails you will receive.
          </p>
          <p>
            Type in your email address and click the Subscribe button below. It takes less than a minute to stay 
            up to date.
          </p>
          <Mailchimp
          action={MailchimpURL}
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true
            }
          ]}
          className='subscribe'
          />
          </Container>
        </div>
    );
}

export default SubscribeBox;