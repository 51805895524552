import React from "react";
import { Container } from "react-bootstrap";
import WebsiteBanner from "../components/WebsiteBanner";
import ContactForm from '../components/ContactForm';

export const Contact = () => (
  <>
    <WebsiteBanner bannerStyle="contactBanner" title="Contact" />
    <Container>      
      <ContactForm />
    </Container>
    <br />
    <br />
  </>
);
export default Contact;
