import React from "react";
import Card from "react-bootstrap/Card";

class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogItem: props.item,
    };
  }

  componentDidMount() {
    var minsToReadUrlWithKey = "/api/minstoread/" + this.state.blogItem.name;

    fetch(minsToReadUrlWithKey, {
      method: "GET"
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        var json = JSON.parse(data);
        if (json.length < 1 || json.minsToRead === undefined) {
          console.error("MinsToRead API returned a response that couldn't be parsed right.");
          return;
        }

        this.setState({ minsToRead: json.minsToRead + " minute(s) to read" });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const linkToPost = "/blog/" + this.state.blogItem.name;
    let thumbnail = "";
    if (this.state.blogItem.image !== undefined && this.state.blogItem.image !== null) {
      const header = `${process.env.REACT_APP_CONTENT_ROOT_URL}/${this.state.blogItem.image}`
      thumbnail = header.substring(0, header.lastIndexOf('.')) + "_thumbnail.jpg";
    }
    else {
      thumbnail = `${process.env.REACT_APP_CONTENT_ROOT_URL}/images/defaultblog_thumb.jpg`
    }

    return (
      <div>
        <Card
          className="mb-3 mr-1 ml-1"
          bg="dark"
          text="white"
          style={{ width: "320px" }}
        >

          <a href={linkToPost} className="nounderline">
            <Card.Img src={thumbnail}
              className="center-cropped" />

            <Card.ImgOverlay
              style={{ height: "6em" }}>
              <Card.Subtitle className="blog-title">
                {this.state.blogItem.date}
              </Card.Subtitle>
              <Card.Text className="blog-title">
                {this.state.blogItem.title}
              </Card.Text> 
            </Card.ImgOverlay>
            
            <Card.Footer className="blog-title">
                <small>{this.state.minsToRead}</small>
            </Card.Footer>
          </a>
        </Card>
        <br />
      </div>
    );
  }
}

export default BlogCard;
