import React from "react";
import { Row } from "react-bootstrap";
import BlogCard from "./BlogCard";
import ArticleIndex from "../components/ArticleIndex";
import ArticlesFilter from "./ArticleFilter";

// Supported categories:  mentalhealth, philosophy,book,career,travel,photography,work,inclusion,writing,hiking,lifelessons
class MoreLikeThis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            more: new Set()
        }
    }
    componentDidMount() {
        const index = new ArticleIndex();
        index.getIndex().then((p) => {
            this.setState({ articles: ArticlesFilter(0, 0, index.data) });
        });
    }
    getMore() {
        let toRet = new Set()
        if (this.props.categories === undefined) {
            return this.latestArticles();
        }
        for (let i = 0; i < this.state.articles.length; i++) {
            let item = this.state.articles[i]

            if (item.categories === undefined || item.name === this.props.name) {
                continue;
            }
            for (let j = 0; j < this.props.categories.length; j++) {
                for (let k = 0; k < item.categories.length; k++) {
                    if (this.props.categories[j] === item.categories[k]) {
                        toRet.add(item);
                        if (toRet.size >= 6) {
                            return toRet;
                        }
                    }
                }

            }
        }
        if (toRet.size < 6) {
            for (let i = 0; i < this.state.articles.length; i++) {
                if (this.state.articles[i].name === this.props.name) {
                    continue;
                }
                toRet.add(this.state.articles[i])
                if (toRet.size === 6)
                    return toRet;
            }
        }
        return toRet;
    }
    latestArticles() {
        return this.state.articles.slice(0, 6);
    }
    hasCommon(first, second) {
        return first.some(x => second.includes(x));
    }

    render() {
        let moreItems = Array.from(this.getMore())

        return (<>
            <br />
            <Row>
                {moreItems.map((item) => (
                    <BlogCard
                        key={item.name}
                        item={item}
                    />
                ))}

            </Row>
        </>);

    }
}
export default MoreLikeThis;