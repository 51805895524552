import React from "react";
import { Container, Row } from "react-bootstrap";
import bookImage from "../assets/daringtobedifferent.jpg";
import Col from 'react-bootstrap/Col';
import daringBookImage from "../assets/daringworkbook.jpg";
import { Carousel } from 'react-bootstrap'

function DaringToBeDifferentWorkbook() {
  return (
    <>

      <Container>
        <div>
          <div className="bookpromo">
            <figure>
              <a href="https://www.amazon.com/dp/B0C52QPWM3">
                <img src={bookImage} alt="alternative source"
                  style={{ maxWidth: '50%', maxHeight: '50%' }} />
              </a>
            </figure>
            <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0C52QPWM3">on Amazon.com</a>.
              If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
            </h5>
          </div>
        </div>
        <br />
        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            <div align="center">
              <img src={daringBookImage} alt="Daring book" s
                style={{ maxWidth: '50%', maxHeight: "50%" }} />
            </div>
          </Col>
          <Col>
            <p>
              Have you read the book of stories and tips for a splendid career in tech, <a href="https://www.amazon.com/dp/B0BPW944MJ">"Daring to be Different"</a>? Do you want to intensify the learning and get more hands-on? Do you want to turn the insights from the book into actions that will transform your life? Check out the newest addition to "Daring to be Different" series - a new workbook to help you apply the lessons from the book to your own situation and goals.
            </p>
            <p>

              The workbook is currently only available as paperback on <a href="https://www.amazon.com/gp/product/B0C4X71MLB">Amazon.com</a>.</p>
            <div>
              <Carousel interval={4000}>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"This book is a goldmine."</p>
                      <p><small><b>Yina Arenas</b>, Partner Group Product Manager, Microsoft</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"I really enjoyed going through the book, and I am positive if you put in the work and reflect, you will find a lot of value and discover yourself: what makes you tick, and what is holding you back."</p>
                      <p><small><b>Kavita Krishnan Kamani</b>, Product Leader and Transformational Coach</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"If you actually put in the work during each lesson of the workbook, it will not only motivate you to be the best version of yourself but you will start believing it."</p>
                      <p><small><b>Mansi Patel</b>, Technical Program Manager, Google</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>

          </Col>
        </Row>

        <br />


        <br />
        <br />
      </Container>
    </>
  );
}

export default DaringToBeDifferentWorkbook;
