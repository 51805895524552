import React from "react";
import { Container } from "react-bootstrap";
import WebsiteBanner from '../components/WebsiteBanner';

export const Portfolio = () => (
  <>
    <WebsiteBanner bannerStyle="porfolioBanner" title="My Writing"/>
    
    <br />
    <br />
    <Container className="themed-container" fluid="sm">
      <h5 className="title">
        
        <br />
        CHECK OUT MY <a href="/blog">BLOG</a> FOR THE LATEST
      </h5>
      <br />
    </Container>
  </>
);

export default Portfolio;
