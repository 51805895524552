// Data structure representing a single blog post

// Encapsulates logic to deal with a single post
class BlogPost {

  // Test helper
  setFileText(text) {
    this.filetext = text;
  }

  getArticleText() {
    return this.filetext;
  }

  // Read from the file system
   async readArticleFile(filename) {
    var url = `${process.env.REACT_APP_CONTENT_ROOT_URL}/${filename}`;
    if (!filename.endsWith(".md")) {
      url = url + `.html`;
    }
    this.setFileText(
      await fetch(url)
        .then(response => (response.text()))
        .catch(error => ("Could not read file: " + error.toString()))
    );
  }
}

export default BlogPost;
