
class ArticleIndex {
  constructor() {
    this.data = undefined;
  }

  async getIndex() {
    var url = `${process.env.REACT_APP_CONTENT_ROOT_URL}/articleIndex.json`;
    return fetch(url)
      .then((response) =>
      {
         return response.json();
      })
      .then((data) => 
      {        
        this.data = data;
      })
      .catch((error) => {
        this.data = "Error fetching json: " + error.toString();
      });
  }
}

export default ArticleIndex;
