import React from "react";
import { Container } from "react-bootstrap";
import KindleClippings from 'react-kindle-clippings'
import WebsiteBanner from "../components/WebsiteBanner";

function KindleHighlights() {
    return (
        <>
            <WebsiteBanner
                bannerStyle="kindleBanner"
                title=""
            ></WebsiteBanner>
            <Container>
            <h5>Highlights From My Kindle</h5>
            <p>I highlight when I read on my kindle, just like I underline in my physical books
                and write on margins. Because, why not? Underlining and making notes help me remember
                the key takeaways from a book that I might later go back to and/or blog about. 
            </p>
            <p>
                Granted some of the highlights you will find might be incomplete or nonsensical
                without context. But you might still find some of interest, especially for
                books we have read in common.
            </p>
            <p>
                FWIW, I present to you all the highlights from my Kindle. 
            </p>
            <small >PS: I built the dropdown below as a reusable open source component that is 
                published on <a href="https://www.npmjs.com/package/react-kindle-clippings">NPM</a>. 
                Open source development truly rocks! 
                Here is my <a href="https://github.com/ThisisRajiRaj/react-kindle-clippings">code repo</a> with 
                the component below if you feel inspired to use it.
            </small>    
            <p className="pb-20"></p>
                <KindleClippings className="pb-20" url="https://thisisrajiraj.github.io/posts/kindleclippings.txt" />
            </Container>
        </>
    );
}
export default KindleHighlights;
