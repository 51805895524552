import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Speaking from "./pages/Speaking.js";
import Contact from "./pages/Contact.js";
import Portfolio from "./pages/Portfolio.js";
import NoMatch from "./pages/NoMatch.js";
import ArticleList from "./pages/ArticleList.js";
import Article from "./pages/Article.js";
import KindleHighlights from "./pages/KindleHighlights.js";
import MyBookShelf from "./pages/BookShelf.js";
import SubscribeBox from './components/SubscribeBox.js'
import DaringToBeDifferent from "./pages/DaringBook.js";
import DaringWorkbook from "./pages/DaringWorkbook.js";
import AIBookRecommender from "./pages/AIBookReco.js";
import { NavigationBar } from "./components/NavBar";

import "./css/App.css";
import "./css/WebsiteBanner.css";
import "./css/Blog.css";
import "./css/NavBar.css";
import "./css/About.css";
import "./css/SubscribeBox.css";
import "./css/Home.css";

function App() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Router>

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/blog" component={ArticleList} />
          <Route exact path="/blog/:name" component={Article} />
          <Route exact path="/about" component={About} />
          <Route exact path="/books" component={MyBookShelf} />
          <Route exact path="/daring" component={DaringToBeDifferent} />
          <Route exact path="/daringworkbook" component={DaringWorkbook} />
          <Route exact path="/highlights" componenst={KindleHighlights} />
          <Route exact path="/subscribe" component={SubscribeBox} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/bookreco" component={AIBookRecommender} />
          <Route exact path="/speaking" component={Speaking} />
          <Route exact path="/bookwithme" component={() => {
            window.location.href = "https://outlook.office.com/bookwithme/user/2553f074e6374131aaf408675c5fceba@microsoft.com?anonymous&ep=plink";
            return null;
          }} />          
          <Route component={NoMatch} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
