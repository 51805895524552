import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import ReCAPTCHA from "react-google-recaptcha";
import { Configuration, OpenAIApi } from "azure-openai";


class AIBookReco extends React.Component {

    constructor(props) {
        super(props);
        this.state = { bookone: '', booktwo: '', bookthree: '', genre: '', type: 'Fiction', airesponse: '' };

        this.handleBookOneChange = this.handleBookOneChange.bind(this);
        this.handleBookTwoChange = this.handleBookTwoChange.bind(this);
        this.handleBookThreeChange = this.handleBookThreeChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleGenreChange = this.handleGenreChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        });
    };

    handleBookOneChange(event) {
        this.setState({ bookone: event.target.value });
    }

    handleBookTwoChange(event) {
        this.setState({ booktwo: event.target.value });
    }

    handleBookThreeChange(event) {
        this.setState({ bookthree: event.target.value });
    }

    handleGenreChange(event) {
        this.setState({ genre: event.target.value });
    }

    handleTypeChange(event) {
        this.setState({ type: event.target.value });
    }

    handleSubmit(event) {
        if (this.state.bookone === "") {
            this.setState({ result: 'Please fill in Book One field before submitting.' })
            return;
        }
        this.setState({ result: 'Generating recommendations...' })
        let titles = [this.state.bookone, this.state.booktwo, this.state.bookthree]
        this.getAIRecommendations(titles)
        event.preventDefault();
    }

    getAIRecommendations(titles) {
        console.log(titles.join(','))
        const openai = new OpenAIApi(
            new Configuration({
                apiKey: process.env.REACT_APP_OPENAI_KEY,
                // add azure info into configuration
                azure: {
                    apiKey: process.env.REACT_APP_OPENAI_KEY,
                    endpoint: process.env.REACT_APP_OPENAI_ENDPOINT,
                    deploymentName: "Davinci-1",
                    apiVersion: "2022-12-01"
                }
            }),
        );
        const type = this.state.type
        const genre = this.state.genre
        let str = titles.join(',')
        let question = "I like to pick next books based on what I have loved in the past. Here are the books I have loved: "
            + str + " List 3 " + type + " books I should consider reading next in genre " + genre + ".\n"

        try {

            const completionFunction = async () => {

                this.setState({ airesponse: "" })
                const response = await openai.createCompletion({
                    model: "Davinci-1",
                    prompt: question,
                    temperature: 0.5,
                    max_tokens: 75,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                    best_of: 1
                })

                let toset = "<br/><h5>Here are three books I found for you:</h5>" + response.data.choices[0].text + "<br/><br/>Hope you enjoy them!"

                this.setState({ airesponse: toset })
            };
            completionFunction();
        } catch (e) {
            console.log("Error getting GPT completion: ", e)
            throw e
        }
    }

    render() {

        return (
            <div style={{ whiteSpace: "pre-wrap" }}>
                <Form>
                    <Form.Label><h6>Hello! I am an AI assistant to help you choose your next book. Just name 3 books you loved, what genre you want to read next, and whether you want to read fiction or nonfiction. I will do my best to recommend 3 books you might like.</h6>
                    </Form.Label>
                    <Form.Group onChange={this.handleBookOneChange}>
                        <Form.Label>Book One<span className="required">*</span></Form.Label>
                        <Form.Control id="book1" required={true} type="text" placeholder="Pride and Prejudice" />
                    </Form.Group>

                    <Form.Group onChange={this.handleBookTwoChange}>
                        <Form.Label>Book Two</Form.Label>
                        <Form.Control id="book2" required={false} type="text" placeholder="Daring to be Different" />
                    </Form.Group>

                    <Form.Group onChange={this.handleBookThreeChange}>
                        <Form.Label>Book Three</Form.Label>
                        <Form.Control id="book3" required={false} type="text" placeholder="1984" />
                    </Form.Group>


                    <Form.Group onChange={this.handleGenreChange}>
                        <Form.Label>Genre</Form.Label>

                        <Form.Control as="select">
                            <option value="Drama">Drama</option>
                            <option value="Fantasy">Fantasy</option>
                            <option value="Classics">Classics</option>
                            <option value="Historical Fiction">Historical Fiction</option>
                            <option value="Humor">Humor</option>
                            <option value="Mystery">Mystery</option>
                            <option value="Science Fiction">Science Fiction</option>
                            <option value="Essay">Essay</option>
                            <option value="Memoir">Memoir</option>
                            <option value="Biography">Biography</option>
                            <option value="Business">Business</option>
                            <option value="Self Help">Self Help</option>
                            <option value="Short Story">Short Story</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formType">
                        <Form.Label>Type</Form.Label>
                        <Form.Check id="type" type="radio" label="Fiction" value="Fiction"
                            checked={this.state.type === "Fiction"} onChange={this.handleTypeChange} />
                        <Form.Check id="type" type="radio" label="Nonfiction" value="Nonfiction"
                            checked={this.state.type === "Nonfiction"} onChange={this.handleTypeChange} />
                    </Form.Group>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={this.onVerify}
                    />
                    <br />
                    <Button variant="primary" type="submit" onClick={this.handleSubmit} disabled={!this.state.verified}>
                        Submit
                    </Button>

                    <h4>{this.state.result}</h4>
                </Form>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.state.airesponse
                    }} />
            </div>
        );
    }

}

export default AIBookReco;