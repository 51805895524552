import React, { Component } from "react";
import BlogPostRenderer from "../components/BlogPostRenderer";
import ArticleIndex from "../components/ArticleIndex";
import Layout from "../components/Layouts";
import MoreLikeThis from "../components/MoreLikeThis"
import CommentForm from "react-comment-form"
import bookImage from "../assets/daringtobedifferent.jpg";
class Article extends Component {
  constructor(props) {
    super();

    const match = props.match;
    if (
      match === undefined ||
      match.params === undefined ||
      match.params.name === undefined
    ) {
      this.state = { error: "Please send in a valid name" };
      return;
    }
    this.state = {
      name: match.params.name,
      image: null,
      caption: "",
      title: "",
      date: 0,
      error: undefined,
      categories: undefined,
      articles: []
    };
  }
  componentDidMount() {
    if (this.state.name === undefined) {
      this.setState({ error: "Please send in a valid article name" });
    }
    const index = new ArticleIndex();
    index.getIndex().then((p) => {
      this.setState({ articles: index.data });
      this.getArticleContent(index.data);
      if (this.props.afterSuccessfulFetch) {
        this.props.afterSuccessfulFetch(this);
      }
    });
  }


  getArticleContent(articles) {
    const fetchedItem = articles.find(
      (item) => item.name.toUpperCase() === this.state.name.toUpperCase()
    );

    if (fetchedItem === undefined) {
      this.setState({ error: "Cannot find named article" });
      return;
    }

    this.setState({ categories: fetchedItem.categories });
    this.setState({ title: fetchedItem.title });
    this.setState({ date: fetchedItem.date });
    this.setState({ caption: fetchedItem.caption });
    const header = fetchedItem.image;
    this.setState({
      image:
        header === null ||
          header === undefined
          ? null
          : `${process.env.REACT_APP_CONTENT_ROOT_URL}/${header}`
    });
  }
  render() {
    let content = <p>{this.state.error}</p>;
    let renderer = this.props.renderer ?? (
      <BlogPostRenderer name={this.state.name} />
    );
    if (this.state.error === undefined) {
      content =
        <>
          <div className="App pt-3 pb-3">
            {/* {this.state.image == null || (
              <figure>
                <img src={this.state.image} alt="alternative source"
                  style={{ maxWidth: '80%', maxHeight: '80%' }} />
                <figcaption style={{ textAlign: "center" }}>
                  <small>{this.state.caption}</small>
                </figcaption>
              </figure>
            )} */}
            {/* {this.state.image == null || (
              <figure>
                <img src={this.state.image} alt="alternative source"
                  style={{ maxWidth: '80%', maxHeight: '80%' }} />
                <figcaption style={{ textAlign: "center" }}>
                  <small>{this.state.caption}</small>
                </figcaption>
              </figure>
            )} */}
            {
              <div>
                <br />

                <figure>
                  <a href="https://www.amazon.com/dp/B0C52QPWM3">
                    <img src={bookImage} alt="alternative source"
                      style={{ maxWidth: '50%', maxHeight: '50%' }} />

                  </a>
                </figure>
                <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0C52QPWM3">on Amazon.com</a>.
                  If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
                </h5>
              </div>

            }
          </div>

          {renderer}
        </>
        ;
    }

    return (
      <Layout>

        <div className="blogpost pb-5">
          <div id="blog-content">{content}</div>
          <br />
          <hr className="rounded" />
          <h4>Please leave a comment below</h4>
          <div className="pb-1">
            <CommentForm parentId={this.state.name} />
          </div>

        </div>
        <br />
        <hr className="rounded" />
        <h4>You might also like</h4>
        <div className="pb-1">
          <MoreLikeThis categories={this.state.categories} name={this.state.name} />
        </div>
        <br />
        <div className="pb-1 App" >
          <a href="/blog">Go to all articles</a>
        </div>
      </Layout>
    );
  }
}

export default Article;