import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaTwitterSquare, FaLinkedin, FaMedium, FaYoutube } from "react-icons/fa";
import HomeCarousel from '../components/HomeCarousel'

export const Home = () => (
  <div className="App-header">
    <HomeCarousel />

    <div className="home">
      <Container fluid>
        <br /><br />
        <Row>
          <p>
            My name is Raji Rajagopalan. I am a Director of Engineering at Microsoft. I love to code, write, build
            businesses, and help people be the best versions of themselves. I am also the author of the book "Daring to be Different: Stories and Tips from a Woman Leader in Tech". Click on one of the links below to read my book.
          </p>
        </Row>
        <br />
        <Row>
          <a href="https://www.amazon.com/dp/B0C52QPWM3">
            Amazon.com
          </a>
          <div style={{ padding: "5px" }}></div>
          <a href="https://www.thirdplacebooks.com/book/9798366276900">
            US Bookstore
          </a>
          <div style={{ padding: "5px" }}></div>
          <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">
            India
          </a>
          <div style={{ padding: "5px" }}></div>
          <a href="https://www.amazon.co.uk/Daring-Different-Stories-Woman-Leader/dp/B0BPW944MJ/">
            Amazon.co.uk
          </a>
          <div style={{ padding: "5px" }}></div>
          <a href="https://www.amazon.ca/Daring-Different-Stories-Woman-Leader/dp/B0BPW944MJ/">
            Canada
          </a>
        </Row>
        <br />
        <Row >
          <Col>
            <a className="pr-3" href="https://twitter.com/thisisrajiraj">
              <FaTwitterSquare />
            </a>
            <a className="pr-3" href="https://linkedin.com/in/rajiraj">
              <FaLinkedin />
            </a>
            <a className="pr-3" href="https://medium.com/@rajigopal">
              <FaMedium />
            </a>
            <a className="pr-3" href="https://www.youtube.com/channel/UCXxRrXC_FR9Vj8Do0cFy5eA">
              <FaYoutube />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);
export default Home;
