import React from "react";
import { Container } from "react-bootstrap";
import WebsiteBanner from "../components/WebsiteBanner";
import AIBookReco from "./AIBookRecommendations";

export const AIBookRecommender = () => (
  <>
    <WebsiteBanner bannerStyle="bookBanner" title="Book Recommender" />
    <Container>      
      <AIBookReco />
    </Container>
    <br />
    <br />
  </>
);
export default AIBookRecommender;
