import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import bookImage from "../assets/daringtobedifferent.jpg";
import shImage from "../assets/sidehustle.jpg";
import adasImage from "../assets/adasbooks.jpg"
import tpbImage from "../assets/thirdplacebooks.jpg";
import wlImage from "../assets/wanderlust.png";
import yuImage from "../assets/yearup.jpg";
import witImage from "../assets/wit.jpg";
import bnImage from "../assets/bn.jpg";
import Button from 'react-bootstrap/Button';
import igniteImage from "../assets/ignite.jpg";
import inspireImage from "../assets/inspire.jpg";
import speakinglogos from "../assets/speakinglogos.jpg"
import widsImage from "../assets/WIDS.jpg"
import whmImage from "../assets/EDWomenAsian5.jpg";
import kclsImage from "../assets/kcls.jpg";
import uwImage from "../assets/uw.jpg";
import iacsImage from "../assets/iacs.jpg";
import unImage from "../assets/un.jpg";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
export const Speaking = () => (
  <>
    <Container>
      <Container>
        <div>
          <div className="bookpromo">
            <figure>
              <a href="https://www.amazon.com/dp/B0BPW944MJ">
                <img src={bookImage} alt="alternative source"
                  style={{ maxWidth: '50%', maxHeight: '50%' }} />
              </a>
            </figure>
            <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0BPW944MJ">on Amazon.com</a>.
              If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
            </h5>
          </div>
        </div>
        <br />
        <h4>If you want to book me to speak at your corporate event or a conference,
          find time on my calendar <a href="https://outlook.office.com/bookwithme/user/2553f074e6374131aaf408675c5fceba@microsoft.com?anonymous&ep=plink">here</a>.</h4>

        <br />
        <h4>Find me in one of these <a href="#events">2023 events</a>.</h4>
        <br />
        <h6 className="title">Featured in events at...</h6>
        <Row>
          <Col>
            <figure>
              <img src={speakinglogos} alt="Speaking logos"
                style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </figure>
          </Col>
        </Row>
        <hr />
        <br />
        <br />
        <br />
        <h6 className="title">See me in action as a keynote speaker <a href="https://youtube.com/playlist?list=PLLnaVlA38jRK09vbZmDKLGbUbApsx9zxT">here</a>.</h6>
        <div>
          <Row>
            <Col>
              <figure>
                <img src={igniteImage} alt="Raji at Ignite"
                  style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </figure>
            </Col>
            <Col>
              <figure>
                <img src={inspireImage} alt="Raji at Inspire"
                  style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </figure>
            </Col>
            <Col>
              <figure>
                <img src={whmImage} alt="Raji talking virtually at an event"
                  style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </figure>
            </Col>
          </Row>
        </div>
        <div>
          <ResponsiveEmbed aspectRatio="16by9">
            <embed src="https://www.youtube.com/embed/videoseries?list=PLLnaVlA38jRK09vbZmDKLGbUbApsx9zxT" />
          </ResponsiveEmbed>
        </div>
        <br />

        <hr />
        <br />
        <h6 className="title">Featured in various podcasts as a guest</h6>
        <div>
          <Row>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/wi2ziMRM_NY" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/8LTzdZ-N3Ro" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/aLYJxVlI4Q0" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/79eBOnUii_0" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
          </Row>

          <Row>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/QWnRrqLFhaU" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
            <Col>
              <div style={{ padding: '10px' }}>
                <ResponsiveEmbed aspectRatio="16by9" >
                  <embed src="https://www.youtube.com/embed/ZN2jgN_yD34" />
                </ResponsiveEmbed>
              </div>
              <br />
            </Col>
          </Row>
        </div>
        <br />
        <hr />

        <h6 id="events" className="title">2023 Events</h6>

        <div>
          <Row >
            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={yuImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    17 Feb 2023, 9AM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Courage is your Superpower, and Other Career Secrets. A Discussion with the students of YearUp Program
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>South Seatte College, Seattle</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://www.yearup.org/locations/wa-seattle-ssc-campus">
                      Learn More
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={shImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    18 Feb 2023, 5PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    A Book Signing and Launch Party with Raji Rajagopalan
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "15px" }}>
                    <small>Side Hustle Taproom, Kirkland</small></div>
                  <Button
                    href="https://www.eventbrite.com/e/book-launch-signing-for-daring-to-be-different-with-raji-rajagopalan-tickets-516211260777">
                    Register Here
                  </Button>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={tpbImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    20 Feb 2023, 7PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Open Mic Book Reading
                  </Card.Text>
                </Card.ImgOverlay>
                <Card.Footer className="blog-title">

                  <small>Third Place Books, Lake Forest Park</small>
                  <Button
                    href="https://www.thirdplacebooks.com/auth-event?page=1">
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </div>
            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={wlImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    5 March 2023, 1PM to 3PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Book Fair and Signing at Wanderlust Book Lounge
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Wanderlust Book Lounge, Bothell</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="/subscribe">
                      Subscribe to be notified
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={widsImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    9 March 2023
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Plenary Talk with Women in Data Science, Cambridge
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Online on Microsoft Teams</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://www.widscambridge.org/conference-schedule">
                      Learn More
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={unImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    14 March 2023
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    UN Panel on Empowering Women and Girls in the Digital Age
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>United Nations HQ, NYC</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://indico.un.org/event/1002946/">
                      Learn More
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={adasImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    16 March 2023, 6:30 PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Courage is your Superpower, and Other Career Secrets. An author event by Raji Rajagopalan
                  </Card.Text>
                </Card.ImgOverlay>
                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Ada's Technical Books & Cafe, Capitol Hill</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://www.facebook.com/events/6493911573970933/?ref=newsfeed">
                      Register Here
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={bnImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    25 March 2023, 2PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Book Signing for Daring to Be Different with Raji Rajagopalan
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Barnes and Noble, Woodinville</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://stores.barnesandnoble.com/event/9780062154801-0">
                      Learn More
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={uwImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    8 April 2023, 2:30PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Daring to be Different. Stories and Career Insights from a Woman Leader in Tech
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Pacific Northwest MBA conference, University of Washington</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://www.uwbmbaa.org/pnw-mba-conference/">
                      Register
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={kclsImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    18 April 2023, 6:30PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Six Career Skills from 20 Years in the Industry. A conversation with author and tech leader Raji Rajagopalan
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>King County Library at Sammamish</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://kcls.bibliocommons.com/events/640a20fe18e5953e00d67cac">
                      Learn More
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>


            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={iacsImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    4 May 2023, 12PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Courage is your Superpower. A talk organized by Indian-American Community Services
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>North Bellevue Community Center</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="/subscribe">
                      Subscribe to be notified
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>
            {/* 
            
            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={onlineImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    19 May 2023, 8:30AM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Three Career Lessons from 20 Years in the Tech Industry. A Online Talk by Raji Rajagopalan
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Online on Zoom</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="/subscribe">
                      Subscribe to be notified
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>
 */}

            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={witImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    11 May 2023, 1:30PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    Daring to be Different
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>Women in Tech Global conference 2023</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="https://www.womentech.net/speaker/Raji/Rajagopalan/82274">
                      Reserve Your Spot
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>


            <div style={{ paddingRight: "10px" }}>
              <Card
                className="mb-3 mr-1 ml-1"
                bg="dark"
                text="white"
                style={{ width: "320px" }}
              >
                <Card.Img src={yuImage}
                  className="center-cropped" />

                <Card.ImgOverlay
                  style={{ height: "6em" }}>
                  <Card.Subtitle className="blog-title">
                    8 June 2023, 6:30PM
                  </Card.Subtitle>
                  <Card.Text className="blog-title">
                    An Indian-American Author Panel
                  </Card.Text>
                </Card.ImgOverlay>

                <Card.Footer className="blog-title">
                  <div style={{ paddingRight: "20px" }}><small>North Bellevue Community Center</small>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <Button
                      href="/subscribe">
                      Subscribe to be notified
                    </Button></div>
                </Card.Footer>
              </Card>
            </div>


          </Row>

        </div>

      </Container>

      <br />
    </Container>
    <br />
    <br />
  </>
);
export default Speaking;
