import React from "react";
import { Carousel } from 'react-bootstrap'
import bookImage from "../assets/daringtobedifferent.jpg";
import workbookImage from "../assets/workbookreveal.jpg";


function HomeCarousel() {
    return (
        <div>
            <Carousel className="pt-5">
                <Carousel.Item>
                    <a href="daringworkbook">
                    <img
                        className="d-block w-100"
                        src={workbookImage}
                        alt="Others"
                    /></a>
                </Carousel.Item>
                <Carousel.Item >
                    <a href="https://www.amazon.com/dp/B0C52QPWM3">
                        <img
                            className="d-block w-100"
                            src={bookImage}
                            alt="New book"
                        />
                    </a>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default HomeCarousel;
