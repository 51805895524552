import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FaTwitterSquare, FaLinkedin, FaMedium, FaYoutube } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', message: '', name: '', result: '', verified: false };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        });
    };


    sendEmail() {
        var headers = new Headers();
        headers.append("Content-Type", "text/json");

        let request = JSON.stringify(
            {
                "fromemail": this.state.email,
                "from": this.state.name,
                "message": this.state.message,
                "devflag": "false"
            });

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: request,
            redirect: 'follow'
        };

        const url = process.env.REACT_APP_SENDMAIL_URL;

        fetch(
            url + "?code=" + process.env.REACT_APP_SENDMAIL_API_KEY,
            requestOptions)
            .then(response => response.text())
            .then(res => { this.setState({ result: "Thanks for sending me a note!" }); })
            .catch(error => { this.setState({ result: "An error occurred. Please try again later." }); });

    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handleMessageChange(event) {
        this.setState({ message: event.target.value });
    }

    handleSubmit(event) {
        if (this.state.message === "" ||
            this.state.name === "" ||
            this.state.email === "") {
            this.setState({ result: 'Please fill in all fields before submitting.' })
            return;
        }
        this.setState({ result: 'Sending your message...' })
        this.sendEmail();
        event.preventDefault();
    }

    render() {
        return (
            <Form>
                <Form.Label><h3>Contact Me</h3>
                    <h2>
                        <a className="pr-3" href="https://twitter.com/thisisrajiraj" >
                            <FaTwitterSquare />
                        </a>
                        <a className="pr-3" href="https://linkedin.com/in/rajiraj">
                            <FaLinkedin />
                        </a>
                        <a className="pr-3" href="https://medium.com/@rajigopal">
                            <FaMedium />
                        </a>              
                        <a className="pr-3" href="https://www.youtube.com/channel/UCXxRrXC_FR9Vj8Do0cFy5eA">
                            <FaYoutube />
                        </a>
                    </h2>
                </Form.Label>
                <Form.Group controlId="formEmail" onChange={this.handleEmailChange}>
                    <Form.Label>Email address<span className="required">*</span></Form.Label>
                    <Form.Control required={true} type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formName" onChange={this.handleNameChange}>
                    <Form.Label>Name<span className="required">*</span></Form.Label>
                    <Form.Control type="text" required={true} placeholder="Enter name" />
                </Form.Group>

                <Form.Group controlId="formMessage" onChange={this.handleMessageChange}>
                    <Form.Label>Message<span className="required">*</span></Form.Label>
                    <Form.Control required={true} as="textarea" placeholder="Message" />
                </Form.Group>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={this.onVerify}
                />
                <br/>
                <Button variant="primary" type="submit" onClick={this.handleSubmit} disabled={!this.state.verified}>
                    Submit
                </Button>
                <h4>{this.state.result}</h4>
            </Form>
        );
    }
}

export default ContactForm;