import React, { Component } from "react";
import { Container } from "react-bootstrap";
import ArticleIndex from "../components/ArticleIndex";
import FilteredBlogPosts from "../components/FilteredBlogPosts";
import bookImage from "../assets/daringtobedifferent.jpg";

class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: []
    }
  }

  componentDidMount() {
    const index = new ArticleIndex();
    index.getIndex().then((p) => {
      this.setState({ articles: index.data });
    });
  }

  render() {
    const content = (this.state.articles)
      ? <FilteredBlogPosts articles={this.state.articles} filteredArticles={this.state.articles} />
      : <></>;
    return (
      <>
        {/* <WebsiteBanner bannerStyle="blogListBanner" title="">
        </WebsiteBanner> */}
        <Container>
          <div>
            <div className="bookpromo">
              <figure>
                <a href="https://www.amazon.com/dp/B0C52QPWM3">
                  <img src={bookImage} alt="alternative source"
                    style={{ maxWidth: '50%', maxHeight: '50%' }} />
                </a>
              </figure>
              <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0C52QPWM3">on Amazon.com</a>.
                If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
              </h5>
            </div>
          </div>
          <br />
          {content}
        </Container>
      </>
    );
  }
}
export default ArticleList;
