

function ArticlesFilter(from, to, articles) {
  let filtered = articles.filter((i) => i.hide !== true);

  if (from !== 0 && to !== 0) {
    filtered = filtered.filter(
      (i) => {
        if (Date.parse(i.date) >= from && Date.parse(i.date) <= to)
          return true;
        return false;
      }
    );
  }
  filtered = filtered.sort((a, b) => (Date.parse(a.date) > Date.parse(b.date) ? -1 : 1));
  return filtered;
}


export default ArticlesFilter;