import React from "react";
import { Container, Row } from "react-bootstrap";
import bookImage from "../assets/daringtobedifferent.jpg";
import Col from 'react-bootstrap/Col';
import adasBooksImage from "../assets/adasbookslogo.jpg";
import villageBooksImage from "../assets/villagebooks.png";
import thirdplaceImage from "../assets/thirdplace.png";
import daringBookImage from "../assets/daringbook.jpg";
import { Carousel } from 'react-bootstrap'

function DaringToBeDifferent() {
  return (
    <>

      <Container>
        <div>
          <div className="bookpromo">
            <figure>
              <a href="https://www.amazon.com/dp/B0C52QPWM3">
                <img src={bookImage} alt="alternative source"
                  style={{ maxWidth: '50%', maxHeight: '50%' }} />
              </a>
            </figure>
            <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0C52QPWM3">on Amazon.com</a>.
              If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
            </h5>
          </div>
        </div>
        <br />
        <h3>Daring to be Different: Stories and Tips from a Woman Leader in Tech</h3>
        <br />
        <Row>
          <Col  xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            <div align="center">
              <img src={daringBookImage} alt="Daring book" s
                style={{ maxWidth: '50%', maxHeight: "50%" }} />
            </div>
          </Col>
          <Col>  
          <p>
            A collection of personal and professional essays, Daring to be Different, has been described as inspirational by readers worldwide. The book offers stories and practical tips to help readers overcome common professional challenges. These tips have worked not only in the author's own career journey but also for many people she has mentored firsthand in the industry.
          </p>
            <div>
              <Carousel interval={4000}>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"Whether you are up and coming in your career, or more established tech leader, Raji's career advice, woven with captivating personal anecdotes, will hold your attention and imagination."</p>
                      <p><small><b>Rob Mauceri</b>, Vice President, Microsoft</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"With a soul-stirring story, Raji Rajagopalan shines a bright light on her life. It’s a deeply moving and empowering book full of practical tips and strong points on how to be successful."</p>
                      <p><small><b>Suma Subramaniam</b>, Author of Namaste is a Greeting and She Sang for India</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
                <Carousel.Item >
                  <div align="center" style={{ borderStyle: 'double', borderColor: 'black', padding: '10px' }}>
                    <blockquote>
                      <p>"This is a great book for anyone wanting to break into a corporate career or succeed once you're in one."</p>
                      <p><small><b>Dona Sarkar</b>, Founder & Chief Creative Officer, Prima Dona Studios</small></p>
                    </blockquote>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>

          </Col>
        </Row>

        <br />
        <p>
          <Row>

            <Col>

            </Col>
          </Row>
        </p>

        <br />

        <h6>Amazon.com</h6>
        <p>
          <ul>
            <li>
              <a href="https://www.amazon.com/Daring-Different-Stories-Woman-Leader-ebook/dp/B0BNKNWPY8">Kindle</a>
            </li>
            <li>
              <a href="https://www.amazon.com/dp/B0BPW944MJ">Paperback</a>
            </li>

            <li>
              <a href="https://www.amazon.com/Daring-Different-Stories-Woman-Leader/dp/B0BN7PD6YF/">Hard cover</a>
            </li>
          </ul>
        </p>
        <br />
        <h6>Barnes and Noble</h6>
        <p>
          <ul>
            <li>
              <a href="https://www.barnesandnoble.com/w/daring-to-be-different-raji-rajagopalan/1142918867?ean=9798823174381">Paperback</a>
            </li>
          </ul>
        </p>
        <br />
        <h6>
          India
        </h6>
        <p>
          <ul>
            <li>
              <a href="https://www.amazon.in/Daring-Different-Stories-Woman-Leader-ebook/dp/B0BNKNWPY8">Kindle</a>
            </li>
            <li>
              <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">Paperback</a>
            </li>
          </ul>
        </p>
        <br />
        <h6>
          Local Bookstores in the US
        </h6>
        <p>
          If you are in the Greater Seattle area, please consider supporting your local bookstore. Most local stores can
          order you the book if you request a copy. For reference, give them the ISBN 979-8366276900 and <a href="https://www.amazon.com/dp/B0BPW944MJ">this link</a> along with the
          book's title. Here are a few featured local bookstores that you can buy from.
        </p>
        <Container>
          <Row style={{}}>
            <Col style={{ margin: '10px', border: 'solid', padding: '10px' }}>
              <figure>
                <a href="https://www.thirdplacebooks.com/book/9798366276900" target="_blank" rel="noopener noreferrer">
                  <img src={thirdplaceImage} alt="Third Place Books"
                    style={{ maxWidth: '50%', maxHeight: '50%' }} />
                </a>
              </figure>
              <figcaption><center>
                <a href="https://www.thirdplacebooks.com/book/9798366276900" target="_blank" rel="noopener noreferrer">
                  <small>Third Place Books</small>
                </a>
              </center>
              </figcaption>
            </Col>
            <Col style={{ margin: '10px', border: 'solid', padding: '10px' }}>
              <figure>
                <a href="https://www.elliottbaybook.com/item/mFQkwVhdxd6R1qD49RFZ9A" target="_blank" rel="noopener noreferrer">
                  <img src={villageBooksImage} alt="Village Books"
                    style={{ maxWidth: '50%', maxHeight: '50%' }} />
                </a>
              </figure>
              <figcaption>
                <center>
                  <a href="https://www.elliottbaybook.com/item/mFQkwVhdxd6R1qD49RFZ9A" target="_blank" rel="noopener noreferrer">
                    <small>Elliot Bay Books</small>
                  </a>
                </center>
              </figcaption>
            </Col>
            <Col style={{ margin: '10px', border: 'solid', padding: '10px' }}>
              <figure>
                <a href="https://www.adasbooks.com/book/9798366276900" target="_blank" rel="noopener noreferrer">
                  <img src={adasBooksImage} alt="Adas Books"
                    style={{ maxWidth: '50%', maxHeight: '50%' }} />
                </a>
              </figure>
              <figcaption>
                <center>
                  <a href=" https://www.adasbooks.com/book/9798366276900" target="_blank" rel="noopener noreferrer">
                    <small>Ada's Technical Books and Cafe</small>
                  </a>
                </center>
              </figcaption>
            </Col>
          </Row>
        </Container>
        <br />
        <h6>
          Anywhere else worldwide
        </h6>
        <p>Go to your region's Amazon website and search for the ISBN 979-8366276900 or with the title "Daring to be Different" and author "Raji Rajagopalan"
        </p>
        <p>
          If you or someone you know is looking to derive more meaning and success from work, this would make for a great read. Order your copy today. Write a review for the book. Share your honest feedback on social media. Above all, go forth and be daring!
        </p>
      </Container>
    </>
  );
}

export default DaringToBeDifferent;
