import React from "react";
import { Container } from "react-bootstrap";
// import BookShelf from "react-goodreads-simple";
import WebsiteBanner from "../components/WebsiteBanner";

function MyBookShelf() {  
  
  const myshelf_url =
    "https://www.goodreads.com/review/list/" +
    process.env.REACT_APP_GOODREADS_USER_ID;
  return (
    <>
      <WebsiteBanner
        bannerStyle="bookBanner"
        title="My Book List"
      ></WebsiteBanner>

      <Container>
        <h3>My book recommendations</h3>
        <br/>
        <small>Last Updated: March 20, 2021</small>
        <h5>
          I read a lot and pointing to just a few books as my favorites is not easy. 
          Here are a few books that have changed my worldview, my life, or left a mark in me in some 
          way. I have listed them here in no particular order.
        </h5>
        
        <br/>
        <h6>
          Fiction
        </h6>
        <p>
          <ul>
            <li>
              <a href="https://www.goodreads.com/book/show/43890641-hamnet">Hamnet</a> by Maggie O'Farrell
            </li>
            <li>              
              <a href="https://www.goodreads.com/book/show/5043.The_Pillars_of_the_Earth">The Pillars of the Earth</a> by Ken Follett
            </li>
            
            <li>              
              <a href="https://www.goodreads.com/book/show/1885.Pride_and_Prejudice">Pride and Prejudice</a> by Jane Austen
            </li>
            
            <li>              
              <a href="https://www.goodreads.com/book/show/4214.Life_of_Pi">Life of Pi</a> by Yann Martel
            </li>
            <li>              
              <a href="https://www.goodreads.com/book/show/13496.A_Game_of_Thrones">The Game of Thrones series</a> by George R. R. Martin
            </li>
          </ul>
        </p>
        <br/>
        <h6>
          Non-Fiction
        </h6>
        <p>
          <ul>
            <li>
              <a href="https://www.goodreads.com/book/show/23692271-sapiens">Sapiens: A Brief History of Humankind</a> by Yuval Noah Harari
            </li>
            <li>              
              <a href="https://www.goodreads.com/book/show/11468377-thinking-fast-and-slow">Thinking Fast and Slow</a> by Daniel Kahneman
            </li>
            
            <li>              
              <a href="https://www.goodreads.com/book/show/747213.The_God_Delusion">The God Delusion</a> by Richard Dawkins
            </li>
            
            <li>              
              <a href="https://www.goodreads.com/book/show/38746485-becoming">Becoming</a> by Michelle Obama
            </li>
            <li>
              <a href="https://www.goodreads.com/book/show/29780253-born-a-crime">Born a Crime</a> by Trevor Noah
            </li>
            <li>
              <a href="https://www.goodreads.com/book/show/18774981-waking-up">Waking Up: A Guide to Spirituality Without Religion</a> by Sam Harris
            </li>
            <li>
              <a href="https://www.goodreads.com/book/show/3450744-nudge">Nudge: Improving Decisions About Health, Wealth, and Happiness</a> by by Richard H. Thaler, Cass R. Sunstein
            </li>
            <li>              
              <a href="https://www.goodreads.com/book/show/13543093-the-better-angels-of-our-nature">
                The Better Angels of Our Nature: Why Violence has Declined</a> and <a href="https://www.goodreads.com/book/show/20821371-the-sense-of-style">
              The Sense of Style: The Thinking Person's Guide to Writing in the 21st Century
              </a> by Steven Pinker
            </li>
            
            <li>              
              <a href="https://www.goodreads.com/book/show/24453082-big-magic">Big Magic</a> by Elizabeth Gilbert
            </li>
          </ul>
        </p>
        <br/>
        <h6 className="pb-3">
          Go to my <a href={myshelf_url}>GoodReads.com bookshelf</a> for more.
        </h6>
        {/* <BookShelf
          apikey={process.env.REACT_APP_GOODREADS_API_KEY}
          userid={process.env.REACT_APP_GOODREADS_USER_ID}
          page="1"
          per_page="30"
          sort="date_read"
        /> */}
      </Container>
    </>
  );
}

export default MyBookShelf;
